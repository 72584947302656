@import './variables';

.landing-page {
  padding-top: 48px;
  margin-bottom: 48px;

  .email-available {
    margin-top: 24px;
    text-align: center;
  }

  .forgot-password {
    margin-top: 24px;
    text-align: center;

    button {
      font-size: 14px;
      color: $color-gold;
      letter-spacing: 0.4px;
      line-height: 24px;
      text-decoration: underline;
      background: none;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    }
  }
}
