@import './variables';

.checkbox-container {
  margin-top: 24px;

  .body {
    background-color: $color-background-light;
    border: 1px solid $color-background-lighter;
    padding: 16px;
    display: flex;

    .text {
      margin-right: 10px;
    }
  }

  .body-error {
    border-top: 1px solid #d6122d;
  }

  .checkbox {
    display: block;
    line-height: 24px;
    padding-left: 16px;
    position: relative;
    right: 0px;
    cursor: pointer;
    outline: none;
    letter-spacing: 0.5px;
  }

  .checkbox::before {
    background-color: $color-background-light;
    border: 1px solid #453f3f;
    box-sizing: border-box;
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    height: 16px;
    width: 16px;
  }

  .checkbox-checked::before {
    background-color: white;
    border-color: white;
  }

  .checkbox-checked::after {
    content: '';
    color: black;
    font-family: iconfont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 8px;
    position: absolute;
    top: 3px;
    left: 3px;
    line-height: 16px;
  }

  .checkbox-error::before {
    border-color: #d6122d;
  }

  .error {
    font-weight: 700;
    color: #d6122d;
  }
}
