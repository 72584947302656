.form-row-button {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.btn {
  background-color: white;
  width: 100%;
  border: 1px solid #1a1919;
  color: #1a1919;
  display: inline-block;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  outline: none;

  font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 16px;
  padding: 16px;
}

.btn-disabled {
  background-color: #b4b4b4;
  border: 1px solid #b4b4b4;
  color: #fff;
  cursor: default;
  pointer-events: none;
}
