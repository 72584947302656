.form-row {
  margin-top: 24px;
  position: relative;

  .label {
    display: flex;
    justify-content: space-between;

    span:nth-child(2) {
      font-size: 12px;
      color: $color-gray-darker;
    }
  }

  .date-info {
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  .reveal {
    cursor: pointer;
    position: absolute;
    right: 24px;
    bottom: 10px;
    z-index: 1;

    img {
      width: 24px;
    }
  }
}

.form-row-error {
  color: #d6122d;
  display: block;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 1px;
  margin: 2px 0;
}
