@import 'variables.scss';

.select-dark {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../images/chevron-thin-down.svg') no-repeat;
  background-position: right 5px top 12px; /* Change the px value here to change the distance */
  background-size: 15px;
  // padding : 4px 20px;
  background-color: $color-background-light;
  border: 1px solid $color-background-light;
  border-radius: 2px;
  color: #a4a4a4;
  font-family: 'ClassGarmnd BT', 'Times New Roman', serif;
  min-width: 200px;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 4px;
  padding: 12px 21px;
  flex-grow: 1;

  &.inputLabel {
    color: #fff;
    line-height: 20px;
    letter-spacing: 0.5px;
  }

  option {
    font-family: 'ClassGarmnd BT';
  }
}

.select-label-dark {
  color: #fff;
  font-size: 15px;
}

.select-icon-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $color-background-light;
  border-bottom-color: $color-gray-light;

  img {
    height: 20px;
  }
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../images/chevron-down.svg') no-repeat;
  background-position: right 10px top 18px; /* Change the px value here to change the distance */
  background-size: 15px;
  padding: 4px 20px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #1a1919;
  font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  letter-spacing: 0.5px;
  margin-top: 4px;
  padding: 12px 21px;
  flex-grow: 1;
  width: 100%;
  font-size: 16px;
  outline: none;
}

.select:focus {
  outline: none;
}
