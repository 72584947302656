@import './variables';

.page-heading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .title {
    font-family: 'ClassGarmnd BT Italic';
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
    color: white;
  }

  .line {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white);
    height: 1px;
    flex-grow: 1;
    margin-left: 16px;
  }
}
