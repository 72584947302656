.input {
  color: #000;
  box-sizing: border-box;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 4px;
  padding: 12px 21px;
  flex-grow: 1;
  height: 58px;
  background-color: #242222;
  border: 1px solid #453f3f;
  color: white;
  font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  padding: 16px 24px;
  outline: none;

  &.password {
    padding-right: 62px;
  }
}

.input-error {
  border-top: 1px solid #d6122d;
}
