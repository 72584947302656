@import './variables';

.gender-container {
  .gender {
    display: flex;
    background-color: $color-background-light;
    border: 1px solid $color-background-lighter;
    margin-top: 4px;

    .item {
      text-align: center;
      flex: 1;
      padding: 16px;
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .item:not(:last-child) {
      border-right: 1px solid $color-background-lighter;
    }

    .item-selected {
      background-color: white;
      color: $color-background;
    }
  }

  .gender-error {
    border-top: 1px solid #d6122d;
  }
}
