@font-face {
  font-family: iconfont;
  src: url(../fonts/iconfont.woff2) format("woff2"), url(../fonts/iconfont.woff) format("woff"), url(../fonts/iconfont.ttf) format("truetype")
}

@font-face {
  font-family: 'ClassGarmnd BT';
  src: url(../fonts/ClassicalGaramondBT-BoldItalic.woff2) format('woff2'),
    url(../fonts/ClassicalGaramondBT-BoldItalic.woff) format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'ClassGarmnd BT Roman';
  src: url(../fonts/ClassicalGaramondBT-Roman.woff2) format('woff2'),
    url(../fonts/ClassicalGaramondBT-Roman.woff) format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ClassGarmnd BT';
  src: url(../fonts/ClassicalGaramondBT-Bold.woff2) format('woff2'),
    url(../fonts/ClassicalGaramondBT-Bold.woff) format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ClassGarmnd BT Italic';
  src: url(../fonts/ClassicalGaramondBT-Italic.woff2) format('woff2'),
    url(../fonts/ClassicalGaramondBT-Italic.woff) format('woff');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: ScalaSansOT;
  src: url(../fonts/ScalaSansOT-Light.woff2) format("woff2"), url(../fonts/ScalaSansOT-Light.woff) format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: ScalaSansOT;
  src: url(../fonts/ScalaSansOT-Black.woff2) format("woff2"), url(../fonts/ScalaSansOT-Black.woff) format("woff");
  font-display: swap;
  font-weight: 900;
  font-style: normal
}

@font-face {
  font-family: ScalaSansOT-Bold;
  src: url(../fonts/ScalaSansOT-Bold.woff2) format("woff2"), url(../fonts/ScalaSansOT-Bold.woff) format("woff");
}

@font-face {
  font-family: ScalaSansOT-BoldIta;
  src: url(../fonts/ScalaSansOT-BoldIta.woff2) format("woff2"), url(../fonts/ScalaSansOT-BoldIta.woff) format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: ScalaSansOT-BlackIta;
  src: url(../fonts/ScalaSansOT-BlackIta.woff2) format("woff2"), url(../fonts/ScalaSansOT-BlackIta.woff) format("woff");
  font-display: swap;
  font-weight: 900;
  font-style: italic
}

@font-face {
  font-family: ScalaSansOT-CondBold;
  src: url(../fonts/ScalaSansOT-CondBold.woff2) format("woff2"), url(../fonts/ScalaSansOT-CondBold.woff) format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: ScalaSansOT-Ita;
  src: url(../fonts/ScalaSansOT-Ita.woff2) format("woff2"), url(../fonts/ScalaSansOT-Ita.woff) format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: ScalaSansOT-LightIta;
  src: url(../fonts/ScalaSansOT-LightIta.woff2) format("woff2"), url(../fonts/ScalaSansOT-LightIta.woff) format("woff");
  font-display: swap;
  font-weight: 300;
  font-style: italic
}

@font-face {
  font-family: ScalaSansOT;
  src: url(../fonts/ScalaSansOT.woff2) format("woff2"), url(../fonts/ScalaSansOT.woff) format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: ScalaSansOT;
  src: url(../fonts/ScalaSansOT-Bold.woff2) format("woff2"), url(../fonts/ScalaSansOT-Bold.woff) format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: ScalaSansOT-Cond;
  src: url(../fonts/ScalaSansOT-Cond.woff2) format("woff2"), url(../fonts/ScalaSansOT-Cond.woff) format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal
}