@import './spin';

.icon {
  font-family: iconfont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none;
  text-transform: none;
  display: inline-block;
  line-height: normal;
}

.icon-check:before {
  content: '';
}

.icon-arrow3--left:before {
  content: '';
}

.icon-my-rituals {
  height: 140px;
  width: 140px;
  background-image: url('../../assets/images/_myrituals_member_logo_static_clean.svg');
  background-size: contain;
}

.icon-blur-anticlockwise {
  width: 160px;
  height: 160px;
  background-image: url('../../assets/images/_myrituals_gradient_blur_background_01.svg');
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: antiClockwiseSpin 5s infinite linear;
  -moz-animation: antiClockwiseSpin 5s linear infinite;
  animation: antiClockwiseSpin 5s infinite linear;
  position: absolute;
  z-index: -1;
}

.icon-blur-clockwise {
  width: 160px;
  height: 160px;
  background-image: url('../../assets/images/_myrituals_gradient_blur_background_02.svg');
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: clockwiseSpin 5s infinite linear;
  -moz-animation: clockwiseSpin 5s linear infinite;
  animation: clockwiseSpin 5s infinite linear;
  position: absolute;
  z-index: -1;
}
