@import './variables';

.upgrade {
  margin-bottom: 48px;

  .terms-notice {
    color: white;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
}
