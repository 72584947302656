footer {
  display: flex;
  flex-direction: column;

  .info {
    background-color: $color-background-light;
    display: flex;
    flex-direction: column;
    align-items: center;


    .heading {
      color: white;
      line-height: 24px;
      font-family: ScalaSansOT-Bold;
      letter-spacing: 0.4px;
      margin-bottom: 16px;
      flex-grow: 1;
    }

    .language {
      margin-top: 24px;
      max-width: $content-width;
      width: 90%;
    }

    .contact {
      margin-top: 40px;
      max-width: $content-width;
      width: 90%;

      .info {
        color: $color-gray-light;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;

        span {
          width: 50%;
        }

        img {
          width: 16px;
          height: 16px;
        }

        a:not(:last-child) {
          margin-right: 24px;
        }
      }
    }

    .logo {
      margin-top: 24px;
      margin-bottom: 40px;

      img {
        width: 64px;
        height: 64px;
      }
    }
  }

  .disclaimer {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-gray-light;
    padding: 24px;

    .copyright {
      max-width: $content-width;
      width: $content-width;
      text-align: center;
      margin-bottom: 8px;
    }

    .links {
      max-width: $content-width;
      width: $content-width;
      display: flex;
      width: 100%;
      justify-content: center;

      a {
        color: #b4b4b4;
        text-decoration: none;
      }

      :not(:last-child) {
        border-right: 1px solid #b4b4b4;
        padding-right: 16px;
      }

      :not(:first-child) {
        padding-left: 16px;
      }
    }
  }
}
