@import './variables';

.forgot-password {
  margin-bottom: 48px;

  .success {
    color: white;
    margin-top: 24px;
    text-align: center;
  }
}

.forgot-password-modal {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  z-index: 100;

  .drag {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;

    .drag-line {
      width: 48px;
      height: 2px;
      border-radius: 1px;
      background-color: #d8d8d8;
      margin-top: 16px;
    }
  }

  .title {
    text-align: center;
    font-family: 'ClassGarmnd BT Roman';
    color: $color-background;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
    max-width: $content-width;
  }

  .text {
    margin-top: 16px;
    max-width: $content-width;
    color: $color-background;
    letter-spacing: 0.4px;
    text-align: center;
  }

  .reset-button {
    height: 48px;
    width: 90%;
    max-width: $content-width;
    margin-top: 24px;
    border: 0;
    background-color: $color-background;
    color: white;
    text-transform: uppercase;
    font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2px;
    cursor: pointer;
  }

  .reset-button-disabled {
    background-color: $color-gray-light;
    cursor: default;
  }

  .cancel {
    margin-top: 24px;
    text-align: center;

    button {
      font-size: 14px;
      color: $color-gold;
      letter-spacing: 0.4px;
      text-decoration: underline;
      background: none;
      border: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    }
  }
}
