@import './variables';

header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .welcome {
    margin-top: 128px;
    text-align: center;
    color: $color-gold;
    font-family: 'ClassGarmnd BT Roman';
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  .my-rituals {
    margin-top: 8px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 1.5px;
    color: white;
    text-align: center;
    font-family: 'ClassGarmnd BT Roman';
  }

  .logo {
    margin-top: 48px;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel {
    width: $content-width;
    outline: none;

    .carousel__dot-group {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      padding-bottom: 48px;
    }

    .carousel__dot {
      padding: 2px;
      border: 0;
      border-radius: 2px;
      outline: none;
      background-color: gray;
    }

    .carousel__dot--selected {
      background-color: white;
    }

    .carousel__dot:not(:last-child) {
      margin-right: 8px;
    }
  }

  .welcome-gift {
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .heading {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 1px;
      font-family: 'ClassGarmnd BT Roman';
    }

    .description {
      color: $color-gray-light;
      width: 240px;
    }
  }

  .header-beam-container {
    height: 600px;
    width: 100%;
    position: absolute;
    z-index: -1;
    display: flex;
    justify-content: center;
    background-color: $color-background;

    .beam {
      position: absolute;
      display: flex;
      justify-content: center;
      overflow: hidden;
      max-width: 100vw;

      .video {
        object-fit: fill;
        height: 600px;
      }
    }

    .gradient {
      position: absolute;
      z-index: 1;
      height: 600px;
      width: 100%;
      background-image: linear-gradient(
        rgba(26, 25, 25, 0),
        black,
        $color-background
      );
    }
  }

  .navigation {
    position: absolute;
    width: $content-width;
  }
}
