@import './variables';

body {
  background-color: $color-background;
  margin: 0;
  color: $color-gray-light;
  font-size: 14px;
  font-family: ScalaSansOT, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  font-style: normal;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'ClassGarmnd BT', 'Times New Roman', serif;
  font-style: normal;
  font-weight: normal;
}

form {
  width: 100%;
}

a {
  color: $color-gold;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.page-content {
  max-width: $content-width;
  width: 90%;
}
