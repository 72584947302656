@import './variables';

.member-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $content-width;
  width: 90%;

  .member-card {
    margin-top: 48px;
    background-color: white;
    max-width: 320px;
    width: 100%;
    border-radius: 16px;

    .image {
      height: 138px;
      width: 100%;
      background-image: url('../../assets/images/membercard-background.png');
      background-size: 462px 308px;
      background-position: -142px -50px;
      background-repeat: no-repeat;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .info {
      padding: 24px;
      display: flex;
      flex-direction: column;
      text-align: right;
      font-family: 'ClassGarmnd BT Roman';

      h2 {
        font-family: 'ClassGarmnd BT Roman';
      }
    }

    .brand {
      color: $color-gold;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      line-height: 16px;
      margin: 0;
    }

    .name {
      color: $color-background;
      margin-top: 8px;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 1px;
      margin-bottom: 0;
    }

    .code {
      color: $color-background;
      letter-spacing: 0.4px;
      font-size: 14px;
      line-height: 24px;
      margin-top: 8px;
      margin-bottom: 0;
    }

    .barcode {
      padding-top: 16px;
      padding-bottom: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .pending {
    margin-top: 48px;
    color: white;
    border: 1px solid $color-background-lighter;
    background-color: $color-background-light;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 56px;
    background-image: url('../../assets/images/icon_system_info.svg');
    background-size: 16px 16px;
    background-position: top 24px right 24px;
    background-repeat: no-repeat;
  }
}
