.member {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 48px;

  .info {
    text-align: center;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 32px;
    color: white;
    font-family: 'ClassGarmnd BT Roman';
  }

  .incentive {
    margin-top: 48px;
    color: white;
    text-align: center;
  }

  .apple-wallet {
    margin-top: 48px;
  }
}

.get-more {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'ClassGarmnd BT Roman';

  .heading {
    margin-top: 48px;
    text-align: center;
    color: $color-gold;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }

  .download {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1px;
    color: $color-background;
    text-align: center;
    max-width: $content-width;
    width: 90%;
    margin-top: 8px;
  }

  .appstore {
    margin-top: 48px;
    width: 218px;
  }

  .play-store {
    margin-top: 24px;
    width: 218px;
  }

  .preview {
    margin-top: 48px;
    width: 274px;
    height: 320px;

    img {
      width: 274px;
      height: 320px;
    }
  }
}
