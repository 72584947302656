@import './variables';

.navigation {
  margin-top: 24px;
  margin-bottom: 24px;

  .back-button {
    height: 48px;
    width: 48px;
    background-color: white;
    border-radius: 24px;
    background-image: url('../images/icon_system_chevron_down_1.2.svg');
    background-repeat: no-repeat;
    background-position: right 14px bottom 14px;
    background-size: 18px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
